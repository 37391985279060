.header {
    background-color: #fff;
    color: #000;
    position: fixed;
    width: 100%;
    z-index: 100;
}

.header__top {
    padding: 0.5rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__topLogin {
    display: flex;
    justify-content: center;
}

.header__logo {
    object-fit: contain;
    height: auto;
    width: 100%;
    max-width: 180px;
}

.header__topMessage {
    background-color: #eee;
    text-align: left;
    font-size: 1rem;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 1.675rem;
    padding-right: 1rem;
}

.header__topMenuIcon {
    width: 34px;
    height: 34px;
    margin-left: 1rem;
}

.header__menuIcon {
    border: none;
    background-color: transparent;
    height: 45px;
    width: 45px;
    cursor: pointer;
}

.header__menuIcon.hide {
    display: none;
}

.header__menuIcon img {
    width: 100%;
    height: auto;
}